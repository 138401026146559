import React, { useState } from 'react';

import CroppedImage from '@sats-group/ui-lib/react/cropped-image';
import Link from '@sats-group/ui-lib/react/link';
import LinkButton from '@sats-group/ui-lib/react/link-button';
import Text from '@sats-group/ui-lib/react/text';

import Spinner from '../../shared-ui/components/spinner/spinner';

import { LogIn as Props } from './log-in.props';

const LogIn: React.FunctionComponent<Props> = ({
  descriptionTexts,
  image,
  link,
  logInHelp,
  redirectText,
  tagline,
  title,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <main className="log-in">
      <div className="log-in__content">
        <div className="log-in__texts-wrapper">
          <Text
            elementName="h1"
            theme={Text.themes.headline}
            italic={true}
            size={Text.sizes.headline1}
            className="log-in__title"
          >
            {title}
          </Text>
          <Text
            className="log-in__tagline"
            theme={Text.themes.headline}
            italic={true}
            size={Text.sizes.large}
          >
            {tagline}
          </Text>
          <div className="log-in__texts">
            {descriptionTexts.map(description => (
              <Text
                className="log-in__text"
                size={Text.sizes.small}
                key={description}
              >
                {description}
              </Text>
            ))}
          </div>
          {isLoading ? (
            <div className="log-in__loading">
              <div className="log-in__spinner">
                <Spinner
                  theme={Spinner.themes.inline}
                  size={Spinner.sizes.small}
                />
              </div>
              <Text theme={Text.themes.emphasis}>{redirectText}</Text>
            </div>
          ) : undefined}
          <LinkButton
            className="log-in__button"
            {...link}
            data-test-login
            variant={LinkButton.variants.primary}
            size={LinkButton.sizes.basic}
            onClick={() => {
              setIsLoading(true);
            }}
          />
          <Link {...logInHelp} />
        </div>
        {image ? (
          <div className="log-in__image">
            <CroppedImage
              {...image}
              className="log-in__image--desktop"
              aspectRatio={CroppedImage.aspectRatios.threeFour}
            />
            <CroppedImage
              {...image}
              className="log-in__image--mobile"
              aspectRatio={CroppedImage.aspectRatios.threeTwo}
            />
          </div>
        ) : null}
      </div>
    </main>
  );
};

export default LogIn;
